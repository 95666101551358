export class Favorites {
    events() {
        let context = this;

        let touchEvent;
        // На некоторых айфонах нет события клик, а на некоторых есть, пойэтому добавить в избранное срабатывает два раза
        if ($(window).width() <= 480) {
            touchEvent = 'mousedown'
        } else {
            touchEvent = 'click'
        }

        // Добавить/убрать избранное
        $(document).on(touchEvent, '.favoritesAdd', function (event) {
            context.switchPrivate($(this));
            return false
        });
    };

    /**
     * Добавление или удаление элемента из избранного
     * @param {object} that 
     */
    switchPrivate(that){
        let context = this;
        
        let productId = that.attr('data-product-id'),
            url = this.langPrefix + '/personal/favorites/' + productId;
        if (that.hasClass('active')) {
            url += '/remove/';
            that.removeClass('active')
        } else {
            url += '/add/';
            that.addClass('active')
        }

        $.post({
            url: url,
            headers: {
                'X-CSRF-TOKEN': this.token
            },
            success: response => {
                context.setCount(response.count);
                if ($('.user-page-wrapper .catalog-item').length != 0) {
                    window.location.reload();
                }
            },
            error: response => {
                console.error('Show error:)');
            }
        })
    }

    /**
     * set favorite counter to dom
     * @param count
     */
    setCountToDom(count) {
        let container = $('header .header_right .cart_lk')
        if (count === 0) {
            container.find('.favoritesLink').attr('data-num', 0);
        } else {
            container.find('.favoritesLink').attr('data-num', count);
        }
    }

    /**
     * Set favorites counter
     * @param count
     */
    setCount(count) {
        if (count === -1) {
            $.get('/personal/favorites/count/').then(response => {
                this.setCountToDom(response.count)
            })
        } else {
            this.setCountToDom(count)
        }
    }

    init() {
        this.langPrefix = $('html').attr('lang') === 'ru' ? '/ru' : '';
        this.token = $('meta[name="csrf-token"]').attr('content');

        this.events();
        this.setCount(-1);
    };
}
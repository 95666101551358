window.$ = window.jQuery = require('jquery');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['x-apikey'] = '123456';




let langPrefix = $('html').attr('lang') === 'ru' ? '/ru' : '';
let token = $('meta[name="csrf-token"]').attr('content');
require('jquery-validation');

require('@fancyapps/fancybox/dist/jquery.fancybox');

import language from "./modules/lang";
import Swiper, { Navigation, Pagination }  from 'swiper';
Swiper.use([Navigation, Pagination]);
let Swal = require('sweetalert2');


import { Favorites } from './modules/favorites';

import { AppBasket } from './modules/order/basket';



let isMobile;


if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) || $(window).width() <= 991) {
    isMobile = true;
} else isMobile = false;


// Модальные окна
function showModal(idModal, redirect) {
    $.fancybox.close();
    $.fancybox.open({
        src: '#' + idModal,
        type: 'inline',
        animationDuration: 200,
        touch: false,
        autoFocus: false,
        beforeShow: function () {
            $('#' + idModal).addClass('flex-wrapper')
        },
        beforeClose: function () {
            if (redirect) {
                document.location.href = '/'
            }
        }
    });
}


$(function () {
    if(window._status == 'e_shop'){
        // инициализация работы с избранным
        let favorite = new Favorites();
        favorite.init();

        // инициализация работы с корзиной
        let basketList = new AppBasket();
    }

    $('.burger').on('click', function () {
        $(this).toggleClass('cross');
        $('.header-anchors').toggleClass('show')
    })

    $('#searchForm > button').on('click', function(e){
        e.preventDefault();
        let searchText = $('#headerSearchInput').val();
        window.location.href = langPrefix + '/categories/sort=sort;numbers=16;text=' + searchText + '/'
    })

    $('.show_autorization_popup').on('click', function (e) {
        e.preventDefault();
        showModal('autorizationBlock', false)
    })

    $('.passwordRecovery-link').on('click', function (e) {
        e.preventDefault();
        showModal('passwordRecovery', false)
    })


    $('#feedbackForm').on('submit', function (e) {
        e.preventDefault();
    })

      function getFormData($form) {
        var unindexed_array = $form.serializeArray();
        var indexed_array = {};

        $.map(unindexed_array, function (n, i) {
            indexed_array[n['name']] = n['value'];
        });

        return indexed_array;
    }

    function formValidator(formName, info, callback) {
        let messageList = {
            email: {
                required: language('home.feedback.email.required'),
                email: language('home.feedback.email.email')
            },
            name: {
                required: language('home.feedback.name.required')
            },
            telephone: {
                required: language('home.feedback.phone.required')
            },
            phone: {
                required: language('home.feedback.phone.required')
            }
        };
        let rulesList = {
            email: {
                required: true,
                email: true
            },
            name: {
                required: true
            },
            telephone: {
                required: true
            }
        };

        let csrf = $('[name="csrf-token"]').attr('content');


        $(formName).validate({
            rules: rulesList,
            messages: messageList,
            submitHandler: function submitHandler(form) {
                $.ajax({
                    url: $(formName).attr('action'),
                    type: "POST",
                    data: getFormData($(formName)),
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                        "X-CSRF-TOKEN": csrf
                    },
                    success: function (data) {
                        if (data.success) {
                            $(formName).find('input[type="text"]').val('');
                            Swal.fire({
                                title: 'Спасибо!',
                                text: 'Наш менеджер свяжется с Вами в ближайшее время',
                                icon: 'success',
                                confirmButtonText: 'Продолжить',
                                showDenyButton: true,
                                timer: 5000,
                                timerProgressBar: true,
                                customClass: {
                                    confirmButton: 'customBtn'
                                }
                            })
                        }
                    },
                    error: function (msg) {
                        Swal.fire({
                            title: 'Ошибка!',
                            text: 'Что-то пошло не так, попробуйте заполнить форму немного позже!',
                            icon: 'error',
                            confirmButtonText: 'Продолжить',
                            showDenyButton: true,
                            customClass: {
                                confirmButton: 'customBtn'
                            }
                        })
                    }
                });
            },
            errorElement: 'span'
        });
    }

    formValidator('#feedbackForm');
    formValidator('#aboutCompanyForm');
    formValidator('#masonryBlockForm');
    formValidator('#findPriceFormForm');

    if($(window).width() <= 991){
        $('.header-anchors a').on('click', function(){
            $('.header-anchors').removeClass('show')
            $('.burger').removeClass('cross')
        })
    }

    
    if ($(window).width() <= 1440) {
        const swiper = new Swiper('.slider', {
            slidesPerView: 1.3,
            lazy: true,
            spaceBetween: 25,
            navigation: {
                nextEl: '.nav-btn_next',
                prevEl: '.nav-btn_prev',
            },
            breakpoints: {
                680: {
                    slidesPerView: 2.5
                }
            },
            
        })
    }

    let brendSlider = document.querySelector('.brend-slider');
    if(brendSlider){
        const brendSwiper = new Swiper('.brend-slider', {
            slidesPerView: 1,
            lazy: true,
            spaceBetween: 15,
            navigation: {
                nextEl: '.nav-btn_next',
                prevEl: '.nav-btn_prev',
            },
            breakpoints: {
                540: {
                    slidesPerView: 2.5,
                },
                1280: {
                    slidesPerView: 3.5,
                },
                1480: {
                    slidesPerView: 4
                }
            },
        })
    }

})
import language from "./../lang";

export class AppBasket {

    constructor() {
        // данные полученные из подгрузки
        this._token = $('meta[name="csrf-token"]').attr('content');
        this.lang = $('html').attr('lang');

        this.prefixUrl = '';
        // определить принадлежность языка и URL
        if (window._locale != window._defaultLocale) {
            this.prefixUrl = '/' + window._locale;
        }

        this.urlBase = this.prefixUrl + '/cart';
        this.urlCount = this.urlBase + '/count/';
        this.urlAdd = this.urlBase + '/add/';
        this.urlDelete = this.urlBase + '/delete/';
        this.urlEdit = this.urlBase + '/update/';
        this.urlDestroy = this.urlBase + '/destroy/';

        this.total = {};

        this.events();
    }

    events() {
        let context = this;
        $(document).on('click', '[data-action="add_basket"]', function () {
            let product = parseInt($(this).attr('data-product-id'));
            let variant = parseInt($(this).attr('data-variant-id'));
            let quantity = parseInt($('[ data-container="quantity"] input[type="number"]').val());

            context.addProduct($(this), product, variant, quantity);

            return false;
        });

        $(document).on('click', '[data-action="delete"]', function () {
            let id = $(this).attr('data-id');

            context.deleteProduct($(this), id);

            return false;
        });

        $('.order-item span[data-action="minus"], .order-item span[data-action="plus"]').on('click', function () {
            var arrow = $(this);
            let container = $(this).closest('.quantity');
            let id = $(this).attr('data-id');
            let quantity = parseInt(container.find('.price-input').val());

            if (arrow.hasClass('plus')) {
                quantity++;
            } else if (arrow.hasClass('minus')) {
                if (quantity != 1) {
                    quantity--;
                }
            }

            context.updateProduct(container.closest('.order-item'), id, quantity);
            return false;
        });

        $('.order-item .price-input').on('change', function () {
            let container = $(this).closest('.quantity');
            let id = $(this).attr('data-id');
            let quantity = parseInt($(this).val());
            
            context.updateProduct(container.closest('.order-item'), id, quantity);
            return false;
        });

        context.setCountSmall();
    }

    getFormData($form) {
        var unindexed_array = $form.serializeArray();
        var indexed_array = {};

        $.map(unindexed_array, function (n, i) {
            indexed_array[n['name']] = n['value'];
        });

        return indexed_array;
    }

    deleteProduct(_this, rowId) {
        let context = this;

        $.ajax({
            type: 'DELETE',
            url: context.urlDelete+rowId+'/',
            dataType: 'json',
            data: {
                id: rowId,
                _token: context._token,
                lang: context.lang
            },
            success: function (response) {
                context.total = response.totals;

                context.setCountSmall();
                context.setTotal();

                _this.closest('.order-item').remove();
            }
        });
    }

    addProduct(_this, productId, offerId, quantity) {
        let context = this;

        axios({
            method: 'post',
            url: context.urlAdd,
            contentType: 'application/json',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': context._token
            },
            data: {
                product_id: productId,
                variant_id: offerId,
                quantity: quantity > 0 ? quantity : 1,
                _token: context._token,
                lang: context.lang
            },
        }).then(response => {
            context.setCountSmall();
            console.log(response);
            if(response.status == 200){
                Swal.fire({
                    title: language('main.modals.basket_success_title'),
                    text: language('main.modals.basket_success_text'),
                    icon: 'success',
                    confirmButtonText: language('main.modals.basket_success_confirm'),
                    cancelButtonText: language('main.modals.basket_success_cancel'),
                    showCancelButton: true,
                }).then((result)=>{
                    if (result.isConfirmed){
                        window.location = '/cart/'
                    }
                })
            } else if(response.status == 201) {
                Swal.fire({
                    title: language('main.modals.basket_error_no_availability_title'),
                    text: language('main.modals.basket_error_no_availability_text'),
                    icon: 'error',
                    // cancelButtonText: language('main.modals.basket_success_cancel'),
                    // showCancelButton: true,
                }).then((result)=>{})
            }


        }).catch(err => {
            console.log(err);
        });
    }

    updateProduct(_this, rowId, quantity) {
        let context = this;

        _this.css('opacity', 0.6);
        axios({
            method: 'put',
            url: context.urlEdit,
            contentType: 'application/json',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': context._token
            },
            data: {
                rowId: rowId,
                quantity: quantity,
                _token: context._token,
                lang: context.lang
            },
        }).then(response => {
            _this.css('opacity', 1);

            context.total = response.data.totals;

            let price = parseFloat(_this.find('.product-item-row [data-params="price"]').attr('data-value'));
            let sum = price * parseInt(quantity);

            // обновить сумму по товару
            _this.find('.total-block .value').text(sum.toFixed(2)+' грн.');

            context.setCountSmall();
            context.setTotal();

        }).catch(err => {
            _this.css('opacity', 1);
            console.log(err);
        });
    }

    /**
     * Обновление коризны до актуальных данных
     */
    setCountSmall() {
        // обновление малой корзины
        let context = this;

        context.items = {};

        axios({
            method: 'get',
            url: this.urlCount,
            contentType: 'application/json',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': context._token
            },
            data: {
                _token: context._token,
                lang: context.lang
            },
        }).then(response => {
            context.total = response.data.totals;

            let count = parseInt(response.data.count);
            let container = $('header .basketLink');
            container.attr('data-num', count);

        }).catch(err => {
            console.log(err);
        });
    }

    setTotal() {
        let sum = this.total.price.toFixed(2);

        $('.your-order-wrapper .form-footer').find('.total-price').text(language('basket.sum_title')+' '+sum+' грн.');

        if (parseInt(this.total.quantity) == 0) {
            document.location = this.prefixUrl + '/cart/';
        }
    }
}